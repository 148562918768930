<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16" fill="#5B5B5B">
              <path d="M2.5 2C2.222656 2 2 2.222656 2 2.5L2 4.5C2 4.777344 2.222656 5 2.5 5L4.5 5C4.777344 5 5 4.777344 5 4.5L5 2.5C5 2.222656 4.777344 2 4.5 2 Z M 3 3L4 3L4 4L3 4 Z M 7 3L7 4L14 4L14 3 Z M 2.5 6C2.222656 6 2 6.222656 2 6.5L2 8.5C2 8.777344 2.222656 9 2.5 9L4.5 9C4.777344 9 5 8.777344 5 8.5L5 6.5C5 6.222656 4.777344 6 4.5 6 Z M 3 7L4 7L4 8L3 8 Z M 7 7L7 8L14 8L14 7 Z M 2.5 10C2.222656 10 2 10.222656 2 10.5L2 12.5C2 12.777344 2.222656 13 2.5 13L4.5 13C4.777344 13 5 12.777344 5 12.5L5 10.5C5 10.222656 4.777344 10 4.5 10 Z M 3 11L4 11L4 12L3 12 Z M 7 11L7 12L14 12L14 11Z" fill="#5B5B5B" />
            </svg>
            <h3 class="pl-2 text-uppercase text-lg">Applicant List</h3>
          </div>
          <div class="flex justify-center">
            <md-button @click.native="exportASCsv()" class="bg-victoria text-white text-uppercase rounded"><i class="flaticon-reading-book mr-2"></i> Export as CSV</md-button>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="mb-4 flex flex-wrap justify-xs-center">
          <div class="relative">
            <Datepicker 
            placeholder="Select Date"
            :value="appliedAt" 
            :format="customFormatter"
            @selected="getAppliedAt" 
            :clearButton="true"
            input-class="w-64 m-1 py-3 pl-3 rounded outline-gray-400"
            />
            <svg class="w-5 date-picker-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
              <path d="M5 0L5 4L7 4L7 0 Z M 17 0L17 4L19 4L19 0 Z M 1 3C0.449219 3 0 3.449219 0 4L0 7C0 7.550781 0.449219 8 1 8L1 24L23 24L23 8C23.550781 8 24 7.550781 24 7L24 4C24 3.449219 23.550781 3 23 3L20 3L20 5L16 5L16 3L8 3L8 5L4 5L4 3 Z M 3 8L21 8L21 22L3 22 Z M 5 10L5 12L7 12L7 10 Z M 9 10L9 12L11 12L11 10 Z M 13 10L13 12L15 12L15 10 Z M 17 10L17 12L19 12L19 10 Z M 5 14L5 16L7 16L7 14 Z M 9 14L9 16L11 16L11 14 Z M 13 14L13 16L15 16L15 14 Z M 17 14L17 16L19 16L19 14 Z M 5 18L5 20L7 20L7 18 Z M 9 18L9 20L11 20L11 18 Z M 13 18L13 20L15 20L15 18 Z M 17 18L17 20L19 20L19 18Z" fill="#5B5B5B" />
            </svg>
          </div>
      
          <!-- <SelectComponent
              :items="ApplicantType"
              :placeholder="false"
              @input="getApplicantType"
              class="w-64 m-1" /> -->

          <SearchBox
              placeholder="Search"
              class="w-84 p-2 m-1 absolute right-0"
              v-model="keyword"
          />
        </div>
        <md-divider></md-divider>
        <Table :items="applicantList" hover-action>
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-sort-by="applicantId" md-label="APPL. ID" class="text-victoria">{{ item.applicantId }}</md-table-cell>
            <md-table-cell md-sort-by="name" md-label="APPLICANT NAME">{{ item.name }}</md-table-cell>
            <md-table-cell md-sort-by="email" md-label="EMAIL ADDRESS">{{ item.email }}</md-table-cell>
            <md-table-cell md-sort-by="contactNumber" md-label="CONTACT NUMBER">{{ item.contact_number }}</md-table-cell>
            <md-table-cell md-sort-by="appliedAt" md-label="APPLIED AT">{{ item.appliedAt }}</md-table-cell>
            <md-table-cell md-sort-by="point" md-label="POINT">{{ item.point }}</md-table-cell>
            <md-table-cell md-sort-by="status" class="text-green font-bold" md-label="STATUS">{{ item.status }}</md-table-cell>
            <span class="action">
              <md-icon class="bg-heather rounded-full p-1 text-white text-base mx-1" @click.native="onShowDetails(item)">visibility</md-icon>
              <!-- <md-icon class="bg-victoria rounded-full p-1 text-white text-base mx-1" @click.native="onShowEdit">edit</md-icon> -->
              <md-icon class="bg-danger rounded-full p-1 text-white text-base mx-1" @click.native="deleteApplication(item.applicantId)">delete</md-icon>
              <!-- <md-icon class="bg-tahiti-gold rounded-full p-1 text-white text-base mx-1" @click.native="onShowEdit">share</md-icon> -->
              <!-- <md-icon class="bg-success rounded-full p-1 text-white text-base mx-1" @click.native="onShowEdit">chevron_right</md-icon> -->
            </span>
          </template>
        </Table>

        <div style="position: absolute; bottom: 0; right: 0"
             class="bg-victoria pointer center w-24 h-24 rounded-full">
             <router-link
              class="mx-3 py-2 text-gray-500 pointer flex "
              to="/job-application"
              active-class="active"
            >
               <md-icon class="text-6xl text-white">add</md-icon>
            </router-link>
         
        </div>
      </CardBody>
    </Card>
    <div class="flex justify-end align-center mt-8">
    
     <Paginate
          :start="meta.current_page"
          :end="meta.last_page"
          :total="meta.last_page"
          :limit="meta.per_page"
          @on-start="onStart"
          @on-end="onEnd"
      />
    </div>
    <Dialog>
      <component :content="content" :is="component"></component>
    </Dialog>
    <ConfirmDialog 
      @confirm="actDeleteApplication(applicantId)"
      @success="deleteSuccess"
      :active.sync="active"
    />
  </div>
</template>

<script>
import {
  Card,
  Table,
  Dialog,
  CardBody,
  Paginate,
  SearchBox,
  SelectComponent,
  ConfirmDialog
} from "@/components";
import { ApplicantInformation, ApplicantScore, BOLShowCreate, PromptEdit } from "@/components/molecule";
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import cms from "@/data/cms";
import { query } from "@/utils/queryString";
import { mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {
    Card,
    Table,
    Dialog,
    Paginate,
    CardBody,
    SearchBox,
    PromptEdit,
    ApplicantInformation,
    ApplicantScore,
    BOLShowCreate,
    SelectComponent,
    Datepicker,
    ConfirmDialog
  },
  data() {

    return {
      currentIndex: 0,
      active: false,
      applicantId: '',
      component: 'BOLShowCreate',
      sortBy: cms.sortBy,
      applicantType: cms.applicantType,
      ApplicantType: cms.ApplicantType,
      content: null,
      keyword: '',
      selectedKeyword: '',
      query: '?',
      visibility: true,
      pagination: true,
      selectedValue: null,
      itemIndex: null,
      status: '',
      appliedAt: '',
      addedAtDateclear: false,
      // addedAt: new Date(),
      searchParams : new URLSearchParams(),
      buttonFilters : cms.buttonFilters
    }
  },
  computed: {
      ...mapGetters({
        applicantList: "applicant/applicantList",
        meta: "applicant/applicantMeta"
      }),
  },
  watch: {
    keyword(oldvalue, newValue) {
        query.set('search', oldvalue)
        // For reseting search result
        if(oldvalue.length < newValue.length && oldvalue.length == 0) {
          this.actGetApplicantList(`?${query.get()}`);
        }
        // For submitting search api after 3 cherecter
        if(oldvalue.length >= 3) {
          this.actGetApplicantList(`?${query.get()}`);
        }
    },
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actGetApplicantList: 'applicant/actGetApplicantList',
      actDeleteApplication: "applicant/actDeleteApplication",
    }),

    customFormatter(date) {
      return moment(date).format('D MMM, YYYY. dddd');
    },
    deleteSuccess(){
      this.actGetApplicantList(`?${query.get()}`);
      this.setShowSnackbar('Application deleted successfully !');
    },
    deleteApplication(applicantId) {
      this.active = true;
      this.applicantId = applicantId;
      // this.setShowSnackbar('Application deleted successfully !');
    },
    getAppliedAt(date) {

      this.selectedValue = date;
      if(this.selectedValue == '' || this.selectedValue == null) {
        query.remove('search');
      }else {
        this.selectedValue = moment(this.selectedValue).format('YYYY-MM-D');
        query.set('search', this.selectedValue);
      }
      this.actGetApplicantList(`?${query.get()}`);
    },
    
    // getApplicantType(value) {

    //   this.selectedValue = value;
    //   if(this.selectedValue == 'all') {
    //     query.remove('job_position');
    //   }else {
    //     query.set('job_position', this.selectedValue);
    //   }
    //   this.actGetApplicantList(`?${query.get()}`);
    // },
    
    exportASCsv(){
      alert('Coming Soon!')
    },
    onShowDetails(item) {
      this.content = item;
      this.component = 'ApplicantInformation';
      this.dialog(true);
    },
    onShowCreate() {
      this.component = 'BOLShowCreate';
      this.dialog(true);
    },
    onShowEdit() {
      this.component = 'PromptEdit';
      this.dialog(true);
    },
    onStart(value) {

      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actGetApplicantList(`?${query.get()}`);
    },
    onEnd(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actGetApplicantList(`?${query.get()}`);
    },
    onPrepareQueryString(key, value) {
      if(this.searchParams.has(key)) {
        this.searchParams.delete(key);
      }
      this.searchParams.append(key, value);
      // console.log(this.searchParams.toString());
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
  },

   created() {
    this.actGetApplicantList()
  }
}

</script>
<style>
    .vdp-datepicker__clear-button{
      position: absolute;
      margin-left: -15px;
      margin-top: 15px;
      padding: -2px;
      font-weight: bold;
      color: red;
    }
    .date-picker-icon {
        top: 15px!important;
        right: 15px!important
    }
</style>